import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const callApi = async (url, options, noJson) => {
	try {
		const response = await fetch(url, options);
		if (!response.ok) handleError(response);
		else {
			if (noJson) return response.text();
			const data = await response.json();
			return data;
		}
	} catch (e) {
		console.error('catchError', e);
		const MySwal = withReactContent(Swal);
			MySwal.fire({
				icon: 'error',
				title: 'Ups!',
				text: 'No se ha podido obtener respuesta del servidor.',
			});
	}
};

const handleError = (response) => {
	console.log('handleError: ', response);
	const MySwal = withReactContent(Swal);
			MySwal.fire({
				icon: 'error',
				title: 'Ups!',
				text: 'No se ha podido obtener respuesta del servidor.',
			});
};

export const callApiPost = async (url, data) => {
	return await callApi(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: getAuthHeaders()
	});
};

export const callApiGet = async (url) => {
	return await callApi(url, {
		method: 'GET',
		headers: getAuthHeaders()
	});
};

const getAuthHeaders = (isJson=true) => {
	return isJson ? {'Content-Type': 'application/json'} : {};
}