import React from 'react';

//bs
import { Container, Row, Col } from 'reactstrap';
import * as Responsive from './Responsive';

//image
import titular from '../css/images/claim-des.png';
import titularMobile from '../css/images/claim-mob.png';

//router
import { NavLink } from 'react-router-dom';

const Footer = () => {
	return (
		<div className="footer">
			<Container>
				<Row>
					<Col md="12">
						<div className="logo-footer">
							<Responsive.Desktop>
								<img
									src={titular}
									alt="La tónica perfecta para el partido perfecto"
									className="img-fluid"
								/>
							</Responsive.Desktop>
							<Responsive.Mobile>
								<img
									src={titularMobile}
									alt="La tónica perfecta para el partido perfecto"
									className="img-fluid"
								/>
							</Responsive.Mobile>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<ul className="links">
							<li>
								<NavLink to="/bases-legales">Bases Legales</NavLink>
							</li>
							<li>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.schweppessuntory.es/contacto"
								>
									Contacto
								</a>
							</li>
							<li>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.schweppessuntory.es/wp-content/uploads/politica-privacidad.pdf"
								>
									Aviso de privacidad
								</a>
							</li>
							<li>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.schweppessuntory.es/terminos-legales.pdf"
								>
									Términos y condiciones
								</a>
							</li>
							<li>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.schweppessuntory.es/politica-cookies.pdf"
								>
									Política de cookies
								</a>
							</li>
						</ul>

						<p className="text-center">
							Suntory Beverage & Food Spain - Todos los derechos reservados
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Footer;
