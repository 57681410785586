import { useField } from 'formik';

//bs
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

const InputCheck = ({ label, ...props }) => {
   const [field, meta] = useField(props.name);

   return (
      <FormGroup className="mb-3 is-checkbox">
         <Input
            {...field}
            {...props}
            id={props.name}
            type="checkbox"
            autoComplete="off"
            className="me-2 sms-checkbox"
         />
         <Label for={props.name}>{label}</Label>
         {meta.touched && meta.error && (
            <FormFeedback>{meta.error}</FormFeedback>
         )}
      </FormGroup>
   );
};

export default InputCheck;