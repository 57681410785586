import React, { useEffect } from 'react';

//Enrutado
import { Route } from 'react-router-dom';
import ReactGA from "react-ga4";

//comps
import Header from './Header';
import Footer from './Footer';
import { ParallaxProvider } from 'react-scroll-parallax';
import CookieConsent from 'react-cookie-consent';

const Layout = ({ component: Component, noCont, isAdmin, ...props }) => {
	
	ReactGA.initialize("G-KJM2M05M3X");

	useEffect(() => {
		props.path && ReactGA.send({ hitType: 'pageview', page: props.path });
	}, [props]);

	return (
		<div className="main-layout">
			<CookieConsent
				location="bottom"
				buttonText="Aceptar"
				style={{ background: '#fff', color: '#000' }}
				buttonStyle={{ color: '#fff', background: '#000', fontSize: '12px' }}
				expires={150}
			>
				Utilizamos cookies de terceros para analizar y mejorar tu navegación. Si
				continuas navegando, consideramos que aceptas su uso. Puedes cambiar la
				configuración u obtener más información en nuestra política de cookies.
			</CookieConsent>

			<div className="main-navigation">
				<Header />
			</div>

			<div className="main-container">
				<ParallaxProvider>
					<Route render={(properties) => <Component {...props} />} />
					<Footer />
				</ParallaxProvider>
			</div>
		</div>
	);
};

export default Layout;
