//main css
import './css/app.scss';

//routing
import { BrowserRouter as Router, Switch } from 'react-router-dom';

//main
import Layout from './components/Layout';

//paginas

import BBLLPage from './pages/legals/BBLLPage';
import HomePage from './pages/HomePage';
import ScrollToTop from './components/ScrollToTop';

//helpers

function App() {
	return (
		<Router>
			<ScrollToTop>
				<Switch>
					<Layout exact path="/" component={HomePage} />
					<Layout path="/bases-legales" component={BBLLPage} />
					<Layout component={HomePage} />
				</Switch>
			</ScrollToTop>
		</Router>
	);
}

export default App;
