import React from 'react';

//bs
import { Container, Row, Col } from 'reactstrap';

//comps
import { NavLink } from 'react-router-dom';
import * as Responsive from './Responsive';

//image
import titular from '../css/images/titular.png';
import titularMobile from '../css/images/texto-cabecera.png';

const Header = () => {
	return (
		<div className="black-header">
			<Container>
				<Row>
					<Col md="8" className="mx-auto">
						<NavLink to="/">
							<Responsive.Desktop>
								<img
									src={titular}
									alt="Descubre como ganar tus entradas para el Mutua Madrid Open"
									className="img-fluid"
								/>
							</Responsive.Desktop>
							<Responsive.Mobile>
								<img
									src={titularMobile}
									alt="Descubre como ganar tus entradas para el Mutua Madrid Open"
									className="img-fluid"
								/>
							</Responsive.Mobile>
						</NavLink>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Header;
