import React from 'react';

//comps
import FormPromo from './comps/FormPromo';

//bs
import { Container, Row, Col } from 'reactstrap';

//images
import Paso1Img from '../css/images/paso-01.png';
import Paso2Img from '../css/images/paso-02.png';
import Paso3Img from '../css/images/paso-03.png';
import Floatings from '../components/common/Floatings';

const HomePage = () => {
	const currentDate = new Date();
	const promoEndDate = new Date('2024-04-18T23:59:59');

	return (
		<div className="home-page">
			<div className="info">
				<Floatings />

				<Container>
					<Row>
						<Col md="10" className="mx-auto">
							<div className="content-1">
								<h1>¿Qué sorteamos?</h1>
								<div className="entradas">
									<div>32</div>
									<div>
										<span>Entradas</span>
										<span>Dobles</span>
									</div>
								</div>
								<div className="subtit f-medium">
									¡2 para semifinales y 2 para finales!
								</div>
							</div>

							<div className="content-2 mt-5 text-center">
								<h1>¿Cómo participar?</h1>
								<div className="pasos">
									<Row>
										<Col sm={4}>
											<img src={Paso1Img} alt="Paso 1" className="img-fluid" />
										</Col>
										<Col sm={4}>
											<img src={Paso2Img} alt="Paso 2" className="img-fluid" />
										</Col>
										<Col sm={4}>
											<img src={Paso3Img} alt="Paso 3" className="img-fluid" />
										</Col>
									</Row>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md="12" className="mt-4 text-center">
							<div className="cta">
								¡Cuánto más participes más posibilidades de ganar!
							</div>
							<div className="promo-terms">
								Promoción válida del 22 de marzo al 18 de abril de 2024
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="participa">
				<Container>
					<Row>
						<Col md="6" className="mx-auto">
							{currentDate > promoEndDate ? (
								<div className="my-5 py-5">
									<h4 className="text-center my-5 py-5">
										La promoción ha finalizado
									</h4>
								</div>
							) : (
								<FormPromo />
							)}
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default HomePage;
