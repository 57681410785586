import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

//----------------------

//export const MAIN_API_URL = 'http://localhost/tonicaperfecta/inc/api';
export const MAIN_API_URL = 'https://www.tonicaperfectapartidoperfecto.com/inc/api';

//----------------------

export const S3_BUCKET = 'tridentstorage';
export const S3_REGION = 'eu-west-1';
export const S3_FOLDER = 'schweppes/';

export const S3_URL =
	'https://tridentstorage.s3-eu-west-1.amazonaws.com/' + S3_FOLDER;

export const S3_ACCESS_KEY = 'AKIAUXOA4FRC23BLAZMM';
export const S3_SECRET_KEY = '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';

//----------------------

export const renameFile = (file) => {
	const extension = file.name.split('.').pop();
	const timestamp = Date.now();
	const randomString = Math.random().toString(36).substring(2, 15);
	const newName = `${timestamp}-${randomString}.${extension}`;
	return new File([file], newName, { type: file.type });
};

//----------------------

export const swalError = (message) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'error',
		title: 'Ups!',
		confirmButtonText: 'Aceptar',
		text: message,
	});
};

export const swalOk = (title, message) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'success',
		title: title,
		confirmButtonText: 'Aceptar',
		text: message,
	});
};

export const swalInfo = (title, message) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'info',
		title: title,
		confirmButtonText: 'Aceptar',
		text: message,
	});
};

export const swalOkAction = (title, message, action, cancel = false) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'success',
		title: title,
		confirmButtonText: 'Aceptar',
		showCancelButton: cancel,
		cancelButtonText: 'Cancelar',
		text: message,
	}).then((result) => {
		if (result.isConfirmed) {
			action();
		}
	});
};

export const formatDate = (str) => {
	return moment(str).format('\\E\\l DD-MM [a las] HH:mm');
};

export const formatDateNormal = (str) => {
	return moment(str).format('DD/MM/YYYY - HH:mm');
};

export const SUPERMERCADOS = [
	{ label: 'Establecimiento', value: 0 },
	{ label: 'Carrefour (Comunidad de Madrid)', value: 'Carrefour' },
	{ label: 'Hiber (Comunidad de Madrid)', value: 'Hiber' },
	{ label: 'Plaza (Comunidad de Madrid)', value: 'Plaza' },
	{ label: 'Hiper Usera', value: 'Hiper Usera' },
	{ label: 'Alcampo', value: 'Alcampo' },
];

export const PRODUCTOS = [
	{ label: 'Producto', value: 0 },
	{
		label: '8 latas Tónica Schweppes',
		value: '8latas',
	},
];
