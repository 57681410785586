import React from 'react';

//bs
import { Container, Row, Col } from 'reactstrap';
import Floatings from '../../components/common/Floatings';

const BBLLPage = () => {
	return (
		<div className="bbll-page">
			<Floatings noBall />
			<Container>
				<Row>
					<Col md="8" className="mx-auto mt-5">
						<h4 className="text-center">
							BASES LEGALES DE LA PROMOCIÓN
							<br />
							-SORTEO ENTRADAS MUTUA MADRID OPEN 2024-
						</h4>
						<div className="textos mt-5">
							<h5>
								<strong>
									<u>1. ORGANIZADOR DE LA PROMOCI&Oacute;N</u>
								</strong>
							</h5>

							<p>
								Mediante las bases que a continuaci&oacute;n se relacionan,{' '}
								<strong>Schweppes, S.A.</strong>, con domicilio social en Calle
								Mahonia 2, 5&ordf; Planta &ndash; Madrid 28043 (en adelante,
								&ldquo;<strong>SCHWEPPES</strong>&rdquo; o el &ldquo;
								<strong>Promotor</strong>&rdquo;) establece las normas
								reguladoras por las que se rige la promoci&oacute;n consistente
								en el sorteo de veintiocho (28) entradas para la fase previa,
								dos (2) entradas dobles para la semifinal y dos (2) entradas
								dobles para la final del Torneo Mutua Madrid Open 2024 celebrado
								entre el 22 de abril al 5 de mayo de 2024. Esta promoci&oacute;n
								ser&aacute; de &aacute;mbito nacional, y car&aacute;cter
								gratuito (en adelante, la &ldquo;
								<strong>Promoci&oacute;n</strong>&rdquo;).
							</p>
							<p>
								Por el mero hecho de participar, los participantes aceptan la
								totalidad de las presentes bases.
							</p>

							<h5>
								<strong>
									<u>2. OBJETO DE LA PROMOCI&Oacute;N</u>
								</strong>
							</h5>

							<p>
								SCHWEPPES realizar&aacute; un sorteo para la promoci&oacute;n de
								sus productos por el cual los participantes podr&aacute;n
								obtener los premios descritos en el apartado 8 de las presentes
								bases legales.
							</p>
							<p>
								Los productos objeto de promoci&oacute;n (en adelante, los
								&ldquo;<strong>Productos Promocionados</strong>&rdquo;):
							</p>
							<p>
								<strong>T&oacute;nica Schweppes &ndash; Latas 33 cl.</strong>
							</p>
							<p>
								Las compras de cualquier producto distinto de los Productos
								Promocionados no ser&aacute;n v&aacute;lidas para participar en
								la Promoci&oacute;n.
							</p>
							<p>
								Tanto los Productos promocionales en general, como cualquiera de
								sus referencias concretas, pueden no estar disponibles de forma
								permanente o transitoria en algunos puntos de venta concretos.
								SCHWEPPES no se responsabiliza por la imposibilidad de encontrar
								los Productos Promocionados o alguna de sus referencias en los
								puntos de venta concretos.
							</p>

							<h5>
								<strong>
									<u>3. &Aacute;MBITO TERRITORIAL DE LA PROMOCI&Oacute;N </u>
								</strong>
							</h5>

							<p>
								La presente Promoci&oacute;n se convoca y se desarrollar&aacute;
								en el territorio peninsular espa&ntilde;ol (en los puntos de
								venta (hipermercados y supermercados) participantes situados en
								territorio espa&ntilde;ol (en adelante &ldquo;
								<strong>Establecimientos Adheridos</strong>&rdquo;).
							</p>

							<h5>
								<strong>
									<u>4. DURACI&Oacute;N DE LA PROMOCI&Oacute;N</u>
								</strong>
							</h5>

							<p>
								La Promoci&oacute;n estar&aacute; en vigor y se
								desarrollar&aacute; desde las 00:00 horas del d&iacute;a 22 de
								Marzo, hasta las 00:00 horas del d&iacute;a 18 de Abril, ambos
								inclusive (en adelante, el &ldquo;
								<strong>Periodo Promocional</strong>&rdquo;).
							</p>
							<p>
								Cualquier participaci&oacute;n recibida fuera del Periodo
								Promocional anteriormente indicado no se considerar&aacute;
								v&aacute;lida.
							</p>
							<p>
								SCHWEPPES se reserva el derecho a prorrogar la duraci&oacute;n
								de la Promoci&oacute;n, previo cumplimiento de las obligaciones
								vigentes.
							</p>
							<p>
								SCHWEPPES se reserva el derecho a suspender, aplazar o cancelar
								la Promoci&oacute;n en cualquier momento, por razones de fuerza
								mayor o caso fortuito y mientras estas perduren,
								reanud&aacute;ndose una vez que se vuelva a la normalidad,
								circunstancia que se pondr&aacute; en conocimiento de los
								participantes. Todo cambio o modificaci&oacute;n en la
								duraci&oacute;n del Periodo Promocional, tendr&aacute; el mismo
								grado y forma de publicidad que las presentes Bases Legales.
							</p>

							<h5>
								<strong>
									<u>5. REQUISITOS DE PARTICIPACI&Oacute;N</u>
								</strong>
							</h5>

							<p>
								S&oacute;lo podr&aacute;n participar en la Promoci&oacute;n
								personas f&iacute;sicas residentes legalmente en territorio
								espa&ntilde;ol incluyen o no Baleares, Canarias, Ceuta y
								Melilla, y que sean mayores de 18 a&ntilde;os, que hayan enviado
								correctamente y dentro del Periodo Promocional la
								correspondiente participaci&oacute;n en cumplimiento de todos
								los requisitos establecidos en las presentes Bases Legales. No
								se permite la participaci&oacute;n de empleados de SCHWEPPES ni
								empresas del grupo ubicadas en Espa&ntilde;a, as&iacute; como
								las empresas colaboradoras en el dise&ntilde;o, desarrollo y
								ejecuci&oacute;n de la presente Promoci&oacute;n, as&iacute;
								como aquellas personas que tengan relaci&oacute;n de parentesco
								hasta el segundo grado y parejas de los anteriormente
								mencionados.
							</p>
							<p>
								SCHWEPPES se reserva el derecho de solicitar la
								exhibici&oacute;n del DNI o Tarjeta de Residencia al ganador(es)
								del Premio en el momento de su entrega para verificar su
								mayor&iacute;a de edad, deneg&aacute;ndose en el caso de que
								&eacute;ste(os) resulte(n) ser menor(es) de 18 a&ntilde;os.
							</p>
							<p>
								No podr&aacute;n participar en esta Promoci&oacute;n los
								empleados de SCHWEPPES ni tampoco de las agencias intervinientes
								en la Promoci&oacute;n, ni los parientes de las anteriores
								personas hasta el segundo grado.
							</p>
							<p>
								La participaci&oacute;n en esta Promoci&oacute;n tiene
								car&aacute;cter gratuito, es decir, los participantes no tienen
								que abonar a SCHWEPPES, ni a ning&uacute;n establecimiento,
								cantidad adicional alguna por el hecho de la
								participaci&oacute;n o por el hecho de obtener los Premios, a
								salvo de los gastos, tasas y/o impuestos que en su caso
								correspondan inherentemente a los ganadores (por ejemplo, los
								correspondientes a la subida del tipo aplicable de IRPF).
							</p>

							<h5>
								<strong>
									<u>6. MEC&Aacute;NICA DE PARTICIPACI&Oacute;N</u>
								</strong>
							</h5>

							<p>
								Para participar en la presente Promoci&oacute;n, los
								participantes deber&aacute;n realizar las siguientes acciones:
							</p>
							<ol>
								<li>
									Adquirir al menos ocho (8) referencias de los Productos
									Promocionados en uno de los Establecimientos Adheridos,
									conservando el ticket de compra para las posteriores
									comprobaciones.
								</li>
								<li>
									Realizar la compra dentro del Per&iacute;odo Promocional. Los
									ocho Productos Promocionados se han de adquirir en unidad de
									acto, bajo el mismo ticket de compra. Se incluye en las
									presentes Bases Legales, la relaci&oacute;n de los
									establecimientos adheridos a la Promoci&oacute;n
									(&ldquo;Establecimientos Adheridos&rdquo;).
								</li>
							</ol>
							<p>
								Acceder a la web [Tonicaperfectapartidoperfecto.com] (en
								adelante, &ldquo;<strong>Web Promocional</strong>&rdquo;) y
								rellenar el formulario de participaci&oacute;n que
								encontrar&aacute;n en la misma, introduciendo su nombre,
								apellidos, e-mail, tel&eacute;fono y rellenar los datos del
								establecimiento donde efectu&oacute; su compra y productos que
								adquiri&oacute;, adjuntando una imagen escaneada o fotografiada
								del ticket de compra de los 8 Productos en Promoci&oacute;n.
							</p>
							<ol start="3">
								<li>Aceptar la mayor&iacute;a de edad.</li>
								<li>
									Aceptar las presentes Bases y la Pol&iacute;tica de Privacidad
									de la Promoci&oacute;n.
								</li>
							</ol>
							<p>
								No podr&aacute;n participar en la Promoci&oacute;n los
								participantes que no cumplan los pasos y acciones descritos
								anteriormente.
							</p>
							<p>
								La participaci&oacute;n estar&aacute; limitada a una
								participaci&oacute;n por ticket de compra, sin que haya
								l&iacute;mite alguno en cuanto al n&uacute;mero de
								participaciones diarias por persona, siempre y cuando cada nueva
								participaci&oacute;n se efect&uacute;e con tickets de compra
								diferentes. Solo se adjudicar&aacute; un (1) premio por persona
								f&iacute;sica y/o domicilio fiscal.
							</p>
							<h6>
								<strong>6.1. Condiciones ticket de compra</strong>
							</h6>
							<p>
								Para que la participaci&oacute;n se considere v&aacute;lida, la
								imagen del ticket de compra que se suba a la Web Promocional,
								debe permitir apreciar claramente los siguientes datos:
							</p>
							<ul>
								<li>Fecha y hora de la compra;</li>
								<li>
									Nombre del Establecimiento Adherido en el que se ha adquirido;
								</li>
								<li>N&uacute;mero del ticket;</li>
								<li>Los ocho (8) Productos Promocionados adquiridos.</li>
							</ul>
							<p>
								El ticket de compra con el que el usuario participe debe ser
								conservado por el mismo hasta el final del Periodo Promocional.
								En caso de resultar premiado, el ticket de compra original
								podr&aacute; ser reclamado por SCHWEPPES para
								comprobaci&oacute;n del cumplimiento de los requisitos de compra
								establecidos en las presentes Bases.
							</p>
							<p>
								No ser&aacute;n v&aacute;lidas a efectos de participaci&oacute;n
								los tickets de compra manipulados, modificados, enmendados o
								alterados de alguna manera, o los que presenten un formato o
								estado que impida la comprobaci&oacute;n de que la compra cumpla
								con los requisitos establecidos en las presentes Bases.
							</p>
							<p>
								Un ticket de compra que cumpla con lo establecido en las
								presentes Bases, dar&aacute; derecho a una (1) &uacute;nica
								participaci&oacute;n, independientemente de si contenga 8
								Productos en Promoci&oacute;n o m&aacute;s.
							</p>
							<p>
								No se admitir&aacute;n participaciones que, entre otros, atenten
								contra los derechos a la intimidad, honor y propia imagen de
								terceros, o que contengan, a modo enunciativo pero no
								limitativo, connotaciones racistas, sexistas, hom&oacute;fobas
								y/o discriminatorias, comentarios, im&aacute;genes, etc.
								obscenos, lascivos, no se admitir&aacute; participaciones con
								contenido pornogr&aacute;fico ni de sexo, difamatorios o
								cualesquiera otros que atenten contra la dignidad de terceros,
								contra la moral y/o contra el orden p&uacute;blico, que inciten
								a la violencia, a pr&aacute;cticas peligrosas, comportamientos
								negligentes, imprudentes o ilegales, y que resulten contrarios
								al buen gusto, las buenas costumbres y todos aquellos trabajos
								que puedan causar rechazo social. No se admitir&aacute;n
								participaciones que inciten a cometer actos perjudiciales para
								la salud y la seguridad de las personas, si se incluye contenido
								peligroso o perjudicial o que contradiga lo anterior, se
								proceder&aacute; a su eliminaci&oacute;n.
							</p>
							<p>
								Adem&aacute;s, ser&aacute;n rechazadas aquellas participaciones
								que, bien directa o indirectamente, contengan cualquier tipo de
								publicidad o reclamo de entidad, marca, producto, actividad o
								servicio alguno, distinto del producto y marca en
								Promoci&oacute;n.
							</p>
							<p>
								Aquellos contenidos que incluyan im&aacute;genes de terceros
								deber&aacute;n contar con la autorizaci&oacute;n previa y por
								escrito de estos. En el caso de menores de edad, deber&aacute;n
								contar con la autorizaci&oacute;n previa y por escrito de los
								progenitores o tutores legales del menor.
							</p>
							<p>
								Los contenidos que se publiquen por los participantes en la
								presente acci&oacute;n promocional, deber&aacute;n haber sido
								realizadas por el participante (&uacute;nico autor) que la suba;
								quedando por tanto prohibido publicar participaciones realizadas
								por terceros o de las que el participante no detente los
								derechos antes referidos, exoner&aacute;ndose, ya desde ahora, a
								SCHWEPPES de toda contingencia y responsabilidad al respecto. En
								consecuencia, &uacute;nicamente podr&aacute;n participar obras
								propias o de las que cuenten con autorizaci&oacute;n. No se
								admiten participaciones de fotograf&iacute;as que no hayan sido
								creados por el participante.
							</p>

							<h5>
								<strong>
									<u>7. SELECCI&Oacute;N DEL GANADOR(ES)</u>
								</strong>
							</h5>

							<p>
								El ganador(es) ser&aacute;(n) seleccionado(s) a trav&eacute;s
								del programa inform&aacute;tico que sostiene el servido de la
								Web Promocional.
							</p>
							<p>
								El usuario que complete correctamente los pasos anteriormente
								mencionados obtendr&aacute; una participaci&oacute;n en la
								Promoci&oacute;n. El programa inform&aacute;tico que sostiene el
								servidor de la Web Promocional comprueba si el momento de
								participaci&oacute;n, entendiendo por tal el momento en que un
								participante, tras completar todos los pasos exigidos, hace clic
								en el bot&oacute;n &ldquo;PARTICIPAR&rdquo;, coincide con un
								&ldquo;Momento Ganador&rdquo; libre. En caso de que la
								participaci&oacute;n del usuario coincida con un &ldquo;Momento
								Ganador&rdquo;, tal usuario resultar&aacute; ganador potencial
								de uno de los premios de la Promoci&oacute;n y el servidor le
								informar&aacute; al instante de ello, indic&aacute;ndole que se
								proceder&aacute; a la validaci&oacute;n de su
								participaci&oacute;n. Se comprobar&aacute; la
								participaci&oacute;n del ganador y tras validaci&oacute;n,
								recibir&aacute; un correo confirm&aacute;ndole que ha sido
								ganador.
							</p>
							<p>
								Habr&aacute; un total de treintaidos (32) &ldquo;Momentos
								Ganadores&rdquo; y diecis&eacute;is (16) &ldquo;Momentos
								Ganadores suplentes&rdquo;.
							</p>
							<p>
								Cada Momento Ganador consistir&aacute; en un d&iacute;a, hora,
								minutos y segundos concretos. Con car&aacute;cter previo al
								inicio de la Promoci&oacute;n SUNTORY presentar&aacute; ante
								Notario la relaci&oacute;n de todos los d&iacute;as, horas,
								minutos y segundos que durante el periodo promocional
								constituir&aacute;n los &ldquo;Momentos Ganadores&rdquo; arriba
								descritos.
							</p>
							<p>
								En caso de anulaci&oacute;n de una participaci&oacute;n, el
								Momento Ganador quedar&aacute; tambi&eacute;n invalidado y se
								activar&aacute; autom&aacute;ticamente uno de los 16 Momentos
								Ganadores Suplentes, siempre y cuando esta anulaci&oacute;n
								est&eacute; dentro del periodo promocional. En caso de no ser
								posible activar uno de los Momentos Ganadores, ya sea por estar
								fuera del periodo promocional o por haberse agotado estos
								Momentos Ganadores Suplentes, el premio quedar&aacute; desierto.
							</p>
							<p>
								La participaci&oacute;n estar&aacute; limitada a una
								participaci&oacute;n por ticket de compra, sin que haya
								l&iacute;mite alguno en cuanto al n&uacute;mero de
								participaciones diarias por persona, siempre y cuando cada nueva
								participaci&oacute;n se efect&uacute;e con tickets de compra
								diferentes. Solo se adjudicar&aacute; un (1) premio por persona
								f&iacute;sica y/o domicilio fiscal.
							</p>
							<p>
								Si no se produjese ninguna participaci&oacute;n v&aacute;lida en
								un determinado Momento Ganador el premio se adjudicar&aacute; a
								la participaci&oacute;n registrada m&aacute;s pr&oacute;xima en
								el tiempo, una vez superada la hora marcada como &ldquo;Momento
								Ganador&rdquo;. Si no existieran participaciones posteriores a
								dicho &ldquo;Momento Ganador&rdquo;.
							</p>
							<p>
								Los participantes se obligan expresamente a garantizar la
								exactitud y veracidad de sus datos de car&aacute;cter personal.
								La falsedad de cualquier tipo de dato, o el incumplimiento de
								cualquiera de los requisitos se&ntilde;alados en las presentes
								Bases, ser&aacute; causa suficiente para la
								descalificaci&oacute;n como participante de la Promoci&oacute;n.
							</p>
							<p>
								En el supuesto de que SCHWEPPES detectase cualquier
								anomal&iacute;a, o sospechasen que cualquier participante, o un
								tercero, est&aacute; incumpliendo las Bases Legales de la
								Promoci&oacute;n y/o impidiendo su normal funcionamiento, se
								reserva el derecho a eliminar su participaci&oacute;n.
							</p>
							<p>
								Los participantes, por el mero hecho de participar en la
								Promoci&oacute;n, aceptan el criterio y competencia de SCHWEPPES
								en cuanto a la resoluci&oacute;n de cualquier cuesti&oacute;n
								derivada de la misma.
							</p>

							<h5>
								<strong>
									<u>
										8. PREMIOS, CONDICIONES DE LA ENTREGA Y COMUNICACI&Oacute;N
										A LOS GANADORES
									</u>
								</strong>
							</h5>

							<p>
								El premio de la presente Promoci&oacute;n consistir&aacute; en:
							</p>
							<ul>
								<li>
									VEINTIOCHO (28) premios, consistentes, cada uno, en un entrada
									doble para asistir al torneo Mutua Madrid Open en Madrid los
									d&iacute;as previos a semifinales y finales con valor nominal
									de 180&euro;.
								</li>
								<li>
									DOS (2) premios, consientes cada uno, en una entrada doble
									para asistir a la semifinal de Mutua Madrid Open en Madrid con
									valor nominal de 700&euro;.
								</li>
								<li>
									DOS (2) premios, consientes cada uno, en una entrada doble
									para asistir a la final de Mutua Madrid Open en Madrid con
									valor nominal de 700&euro;.
								</li>
							</ul>
							<p>
								Tras verificar su participaci&oacute;n y comprobar que tanto los
								datos como el ticket son correctos, el ganador recibir&aacute;
								una confirmaci&oacute;n por correo electr&oacute;nico
								comunic&aacute;ndole la validaci&oacute;n de su
								participaci&oacute;n y deber&aacute; seguir los siguientes
								pasos.
							</p>
							<p>
								El ganador deber&aacute; remitir rellena la carta de
								aceptaci&oacute;n enviada en un email para poder recibir su
								premio via correo electr&oacute;nico en un plazo m&aacute;ximo
								de 48 horas desde que se le haya notificado la condici&oacute;n
								de ganador para poder efectuar el env&iacute;o de su premio.
							</p>
							<p>
								Si en el plazo m&aacute;ximo de 48 horas, a partir de la fecha
								del contacto, el ganador/a no suministra la informaci&oacute;n
								indicada en el p&aacute;rrafo anterior, se entender&aacute; la
								no aceptaci&oacute;n de este, perdiendo el ganador/a todo
								derecho de recibir el premio.
							</p>
							<p>
								Una vez revisada y validada toda la documentaci&oacute;n, se
								proceder&aacute; a confirmar la entrega del premio y a realizar
								el env&iacute;o en un periodo m&aacute;ximo de 96 horas
								(excluyendo festivos).
							</p>
							<p>
								Los env&iacute;os estar&aacute;n sujetos a posibles demoras o
								circunstancias que est&eacute;n fuera del control razonable del
								Promotor.
							</p>
							<p>
								En caso de que el ganador potencial no remita la
								documentaci&oacute;n solicitada en los plazos arriba
								establecidos, de identificaci&oacute;n incompleta, incorrecta,
								imposibilidad de localizar al ganador o incumplimiento de alguno
								de los requisitos exigidos en las presentes bases promocionales,
								o en caso de que no quiera o no pueda disfrutar del premio, el
								ganador perder&aacute; el derecho a obtener el premio. En el
								caso de que una participaci&oacute;n ganadora quede invalidada
								por alguno de los motivos anteriores, el momento ganador que
								estuviera asignado a esta participaci&oacute;n perder&aacute; la
								condici&oacute;n de ganador, y, por lo tanto, el premio
								volver&aacute; a estar activo y libre para ser adjudicado a la
								siguiente participaci&oacute;n v&aacute;lida. En caso de que el
								periodo promocional ya haya finalizado y no sea posible
								reactivar el momento ganador el premio quedar&aacute; desierto.
							</p>
							<p>
								SCHWEPPES no es responsable del buen funcionamiento y calidad
								del Premio ofrecido que en todo caso corresponder&aacute; al
								proveedor y/o fabricante de este.
							</p>
							<p>
								El Premio es personal e intransferible y no es canjeable por su
								valor met&aacute;lico ni por otros premios.
							</p>
							<p>
								Queda prohibida la comercializaci&oacute;n y/o venta del Premio
								o del derecho a la obtenci&oacute;n del mismo.
							</p>
							<p>
								Todos los impuestos y tasas relacionados con el Premio de esta
								Promoci&oacute;n o aquellos gastos adicionales motivados por
								demora o negligencia por parte del participante/es ser&aacute;n
								por cuenta de &eacute;ste, salvo que de otra forma, se hubiera
								establecido expresamente en las presentes bases.
							</p>
							<p>
								Esta Promoci&oacute;n no es acumulable a otras ofertas y/o
								promociones.
							</p>
							<p>
								El participante ganador deber&aacute; confirmar el d&iacute;a de
								la entrega del Premio sus datos personales, as&iacute; como
								entregar copia de su DNI o Tarjeta de Residencia y firmar el
								correspondiente escrito de aceptaci&oacute;n del Premio, si
								fuere requerido a ello. La cumplimentaci&oacute;n de los datos
								personales y fiscales del(os) ganador(es) necesarios para la
								entrega del Premio tiene car&aacute;cter obligatorio, de forma
								que la ausencia de cualquiera de dichos datos supondr&aacute; la
								p&eacute;rdida del derecho al Premio obtenido
							</p>

							<h5>
								<strong>
									<u>9. RESPONSABILIDADES</u>
								</strong>
							</h5>

							<p>
								Cualquier intento de participaci&oacute;n abusiva o fraudulenta
								dar&aacute; lugar a la consiguiente descalificaci&oacute;n del
								participante en la Promoci&oacute;n. SCHWEPPES se reserva la
								facultad de adoptar cuantas medidas resulten oportunas para
								evitar cualquier conducta de la que sospeche que tenga por
								finalidad o efecto cualquier intento de actuar en fraude de la
								presente Promoci&oacute;n o en incumplimiento de sus normas o en
								perjuicio de otros participantes, cuya primera e inmediata
								consecuencia ser&aacute; la exclusi&oacute;n del participante y
								la p&eacute;rdida de todo derecho al Premio que eventualmente
								hubiese obtenido.
							</p>
							<p>
								Asimismo, SCHWEPPES se reserva el derecho a excluir a los
								participantes en caso de aportar o colgar material que contenga
								virus, gusanos o cualquier otro c&oacute;digo inform&aacute;tico
								dirigido a o apto para da&ntilde;ar, interferir, interceptar o
								vulnerar la seguridad de cualquier sistema, informaci&oacute;n o
								datos, as&iacute; como cuando los datos personales facilitados
								sean incorrectos, incompletos o falsos.
							</p>
							<p>
								SCHWEPPES no ser&aacute; responsable de los servicios que
								terceras empresas deban prestar con motivo de la presente
								Promoci&oacute;n o del Premio, ni de p&eacute;rdidas,
								deterioros, robos, retrasos o cualquier otra circunstancia
								imputable a terceros o a Internet que puedan afectar al
								desarrollo de la Promoci&oacute;n. En ning&uacute;n caso
								SCHWEPPES ser&aacute; responsable de los servicios que cualquier
								otra entidad con la que &eacute;sta colabore, preste al ganador
								como consecuencia del Premio entregado. &nbsp;En concreto,
								SCHWEPPES no se hace responsable de las incidencias y sucesos de
								todo orden (por ejemplo, imposibilidad de registrar los datos de
								alg&uacute;n participante, interrupci&oacute;n o p&eacute;rdida
								de conexi&oacute;n...) que sean provocadas por una aver&iacute;a
								en el programa inform&aacute;tico debido a un caso de fuerza
								mayor, fallos de software o hardware ni por los posibles errores
								en la introducci&oacute;n y/o el procesamiento de respuestas,
								entregas o datos personales; ni tampoco de las incidencias que
								tengan su origen en una aver&iacute;a en la red
								telef&oacute;nica, inform&aacute;tica, el&eacute;ctrica, u otros
								sucesos similares. En caso de producirse problemas o incidencias
								de este tipo, SCHWEPPES har&aacute; todo lo que est&eacute; en
								su mano para corregirlas lo antes posible, pero no puede asumir
								ninguna responsabilidad al respecto.
							</p>
							<p>
								SCHWEPPES excluye cualquier responsabilidad por da&ntilde;os y
								perjuicios de toda naturaleza que puedan deberse a la falta
								temporal de disponibilidad o de continuidad del funcionamiento
								de las redes sociales mediante las cuales se participa en la
								Promoci&oacute;n, a la defraudaci&oacute;n de la utilidad que
								los usuarios hubieren podido atribuir al mismo, y en particular,
								aunque no de modo exclusivo, a los fallos en el acceso a las
								distintas p&aacute;ginas, y env&iacute;os de respuestas de
								participaci&oacute;n a trav&eacute;s de Internet.
							</p>
							<p>
								SCHWEPPES no se responsabiliza del uso que del Premio realice el
								ganador. La obligaci&oacute;n de SCHWEPPES queda limitada a
								entregar el Premio ofrecido en las condiciones se&ntilde;aladas
								y con los requisitos establecidos en las presentes Bases. En
								consecuencia, SCHWEPPES no ser&aacute; responsable de
								ning&uacute;n da&ntilde;o o perjuicio, de cualquier naturaleza,
								que pudiera sufrir el ganador y/o un tercero, con motivo de o en
								relaci&oacute;n con la obtenci&oacute;n y posterior uso del
								Premio.
							</p>
							<p>
								Asimismo, SCHWEPPES no se hace responsable de los defectos o
								vicios ocultos que pueda presentar el Premio y que sean
								imputables a la empresa fabricante o distribuidora del mismo, ni
								tampoco de las incidencias que tengan su origen en supuestos de
								fuerza mayor derivados de causas tales como una aver&iacute;a en
								la red telef&oacute;nica, inform&aacute;tica, el&eacute;ctrica,
								provocadas por agentes externos naturales, atmosf&eacute;ricos,
								o bien por un deficiente funcionamiento de las
								compa&ntilde;&iacute;as suministradoras de dichos servicios o,
								en general, por causas que no le sean imputables.
							</p>
							<p>
								Los participantes se obligan expresamente a garantizar la
								exactitud y veracidad de sus datos de car&aacute;cter personal.
								La falsedad de cualquier tipo de dato, o el incumplimiento de
								cualquiera de los requisitos se&ntilde;alados en las presentes
								Bases, ser&aacute; causa suficiente para la
								descalificaci&oacute;n como participante de la presente
								Promoci&oacute;n.
							</p>

							<h5>
								<strong>
									<u>
										10. PROPIEDAD INDUSTRIAL E INTELECTUAL Y DERECHOS DE IMAGEN
									</u>
								</strong>
							</h5>

							<p>
								Todo participante en la presente Promoci&oacute;n se compromete
								y obliga a respetar todas y cada una de las disposiciones,
								t&eacute;rminos y exigencias establecidos por las leyes
								reguladoras de la Propiedad Industrial as&iacute; como por las
								normas reguladoras de la Propiedad Intelectual y de Derechos de
								Imagen.
							</p>
							<p>
								En virtud de lo anteriormente expuesto, el participante se
								compromete y obliga a respetar todos y cada uno de los derechos
								de Propiedad Industrial e Intelectual y de Imagen que protejan
								obras, contenidos, marcas u otros signos distintivos, y/o
								nombres de dominio, e imagen, a los que puedan acceder a
								trav&eacute;s de la participaci&oacute;n en la presente
								Promoci&oacute;n.
							</p>
							<p>
								La titularidad de todos y cada uno de los derechos de Propiedad
								Industrial e Intelectual y de Imagen que identifican a SCHWEPPES
								y las marcas que participan en la Promoci&oacute;n, tales como
								nombres, marcas, logotipos, iconos o cualquier otro elemento
								distintivo, son de exclusiva titularidad de dichas marcas, salvo
								expresa indicaci&oacute;n de una titularidad distinta.
							</p>
							<p>
								Los participantes autorizan expresamente a SCHWEPPES para que
								ceda, utilice, publique y reproduzca su nombre, voz y/o imagen
								sin limitaci&oacute;n temporal ni territorial, en los distintos
								soportes de comunicaci&oacute;n, incluidos peri&oacute;dicos,
								revistas, p&aacute;ginas web, folletos informativos, videos
								promocionales, etc., con la intenci&oacute;n de publicitar la
								Promoci&oacute;n e informar sobre su desarrollo y resultados, de
								acuerdo con los l&iacute;mites establecidos en las leyes
								espa&ntilde;olas. Asimismo, autorizan su uso en cualquier tipo
								de publicidad, promoci&oacute;n, publicaci&oacute;n, incluido
								televisi&oacute;n, internet, redes sociales o cualquier otro
								medio de la naturaleza que sea, con fines comerciales,
								informativos o corporativos, siempre que est&eacute;n
								relacionados con la Promoci&oacute;n. Dicha cesi&oacute;n se
								refiere a la totalidad de usos posibles para publicitar la
								Promoci&oacute;n, con excepci&oacute;n de aquellas utilizaciones
								que pudieran atentar contra el derecho al honor en los
								t&eacute;rminos previstos en la Ley Org&aacute;nica 1/82, de 5
								de Mayo, de Protecci&oacute;n Civil al Derecho al Honor, la
								Intimidad Personal y familiar y a la Propia Imagen.
							</p>
							<p>
								Los participantes no tendr&aacute;n derecho a recibir
								ning&uacute;n tipo de contraprestaci&oacute;n por la
								autorizaci&oacute;n al uso de su nombre, voz y/o imagen en los
								t&eacute;rminos expuestos en esta cl&aacute;usula.
							</p>
							<p>
								Si un participante se opone al uso de su imagen, voz y/o nombre
								en los t&eacute;rminos antes expuestos, se compromete a
								comunicarlo por escrito a SCHWEPPES. El participante
								podr&aacute; revocar en cualquier momento su consentimiento
								solicitando la retirada de la fotograf&iacute;a / imagen o
								nombre mediante notificaci&oacute;n por escrito dirigida
								Schweppes, S.A. con domicilio en Paseo de la Castellana, 202,
								28046 Madrid o por mail a la direcci&oacute;n de correo:{' '}
								<a href="mailto:atencionalconsumidor@suntory.com">
									atencionalconsumidor@suntory.com
								</a>
							</p>

							<h5>
								<strong>
									<u>
										11. PROTECCI&Oacute;N DE DATOS DE CAR&Aacute;CTER PERSONAL
									</u>
								</strong>
							</h5>

							<p>
								Los participantes en la presente Promoci&oacute;n consienten que
								sus datos personales sean tratados conforme a las disposiciones
								del Reglamento General de Protecci&oacute;n de Datos Reglamento
								(EU) 2016/679 (&ldquo;RGPD&rdquo;), la Ley Org&aacute;nica
								3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos
								Personales y garant&iacute;a de los derechos digitales y
								dem&aacute;s normativa aplicable, para el desarrollo de la
								presente Promoci&oacute;n en los t&eacute;rminos descritos a
								continuaci&oacute;n:
							</p>
							<p>&nbsp;</p>
							<table>
								<tbody>
									<tr>
										<td>
											<p>
												<strong>&iquest;</strong>
												<strong>
													Qui&eacute;n es el responsable del tratamiento de sus
													datos?
												</strong>
											</p>
											<p>Schweppes, S.A.</p>
											<p>CIF: A-28075976</p>
											<p>Direcci&oacute;n: Calle Mahonia, 2, 28043 Madrid</p>
											<p>
												Si tiene alguna consulta relativa a protecci&oacute;n de
												datos puede dirigirse a la Oficina de Protecci&oacute;n
												de datos de SCHWEPPES a trav&eacute;s del correo{' '}
												<a href="mailto:GDPR.Info@suntory.com">
													GDPR.Info@suntory.com
												</a>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>
													&iquest;Qui&eacute;n es el encargado del tratamiento
													de sus datos? IDEAS DESDE CERO S.L
												</strong>
											</p>
											<p>
												<u>CIF</u>: B-86272564
											</p>
											<p>
												<u>Direcci&oacute;n</u>: C/ Manuel Uribe 13-15, (28033),
												Madrid
											</p>
											<p>
												SCHWEPPES como responsable e ideas IDEAS DE CERO como
												encargado de tratamiento se obligan al cumplimiento de
												lo dispuesto en el RGPD y cualquier norma que pueda
												modificarlo o sustituirlo en el futuro, as&iacute; como
												dem&aacute;s normativa en materia de protecci&oacute;n
												de datos que resulte aplicable en cada momento.
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>
													&iquest;Qu&eacute; tipo de datos hemos obtenido?{' '}
												</strong>
											</p>
											<p>
												[X] Datos de car&aacute;cter identificativos:&nbsp;
												entre los que se incluyen nombre y apellidos,
												n&uacute;mero de tel&eacute;fono, direcci&oacute;n
												postal y/o correo electr&oacute;nico. Imagen.
											</p>
											<p>[&nbsp; ] Otros (especificar)</p>
											<p>
												<strong>
													&iquest;C&oacute;mo hemos obtenido sus datos?
												</strong>
											</p>
											<p>
												Los datos personales que SCHWEPPES trata son los datos
												personales que usted ha introducido / facilitado a
												trav&eacute;s del formulario de la p&aacute;gina web de
												la promoci&oacute;n Tonicaperfectapartidoperfecto.com.
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>
													&iquest;Con qu&eacute; finalidad y legitimaci&oacute;n
													tratamos sus datos personales?
												</strong>
											</p>
											<p>
												La base legal para el tratamiento de sus datos es el
												consentimiento que usted nos otorga en{' '}
												<em>
													hacer referencia al formulario de registro de la
													p&aacute;gina web de la Promoci&oacute;n.{' '}
												</em>
											</p>
											<p>
												En SCHWEPPES tratamos la informaci&oacute;n que nos
												facilitan los participantes con el fin de:
											</p>
											<p>
												(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												gestionar su participaci&oacute;n en la presente
												Promoci&oacute;n; y
											</p>
											<p>
												(ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												entregar el Premio(s) derivados de la presente
												Promoci&oacute;n.
											</p>
											<p>
												En cualquier momento Ud. podr&aacute; ejercer sus
												derechos en materia de protecci&oacute;n de datos a
												trav&eacute;s del mail{' '}
												<a href="mailto:GDPR.Info@suntory.com">
													GDPR.Info@suntory.com
												</a>
												, identific&aacute;ndote a trav&eacute;s del DNI y
												especificando el derecho que deseas ejercitar. Has
												detener en cuenta que posiblemente, si no podemos tratar
												tus datos personales no puedas participar en el presente
												sorteo.
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>
													&iquest;Por cu&aacute;nto tiempo conservaremos sus
													datos?
												</strong>
											</p>
											<p>
												Los datos personales proporcionados se
												conservar&aacute;n durante el tiempo m&iacute;nimo
												necesario e imprescindible para ejecutar esta
												Promoci&oacute;n y cumplir las obligaciones impuestas
												por la legislaci&oacute;n aplicable. Una vez
												transcurridos dichos plazos, permanecer&aacute;n
												bloqueados hasta su eliminaci&oacute;n, mientras puedan
												derivarse responsabilidades legales para SCHWEPPES.
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>
													&iquest;A qu&eacute; destinatarios se
													comunicar&aacute;n sus datos?{' '}
												</strong>
											</p>
											<p>
												SCHWEPPES s&oacute;lo comunicar&aacute; sus datos a:
											</p>
											<p>
												&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												IDEAS DESDE CERO S.L, a la sociedad provista de CIF
												n&uacute;mero, B-86272564 y con domicilio en CALLE
												MANUEL URIBE 13-15, 28033 MADRID, como encargada de
												tratamiento y como gestora de la presente
												Promoci&oacute;n en nombre de SCHWEPPES; y
											</p>
											<p>
												&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												a aquellos terceros, organismos e instituciones
												p&uacute;blicas de la Administraci&oacute;n General del
												Estado, de las administraciones auton&oacute;micas y
												locales, incluidos los &oacute;rganos jurisdiccionales a
												los que est&eacute; legalmente obligado a facilitarlos.
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												<strong>
													&iquest;Cu&aacute;les son sus derechos cuando nos
													facilita sus datos?&nbsp;{' '}
												</strong>
											</p>
											<p>
												Los derechos de protecci&oacute;n de datos de los que
												son titulares los participantes en la Promoci&oacute;n
												son:
											</p>
											<p>
												(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												derecho a solicitar el acceso a los datos personales
											</p>
											<p>
												(ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												derecho de rectificaci&oacute;n de los datos que
												considere inexactos o a su supresi&oacute;n si ya no son
												necesarios para cumplir con la finalidad por la que
												fueron recabados
											</p>
											<p>
												(iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												derecho a oponerse al tratamiento que se realiza de los
												datos
											</p>
											<p>
												(iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												derecho a solicitar la limitaci&oacute;n de su
												tratamiento: en determinadas circunstancias,
												podr&aacute;n solicitar la limitaci&oacute;n del
												tratamiento de sus datos, en cuyo caso &uacute;nicamente
												los conservaremos para el ejercicio o defensa de
												reclamaciones
											</p>
											<p>
												(v)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												derecho a la portabilidad de los datos, teniendo derecho
												a recibir por parte del responsable del tratamiento, de
												forma ordenada y estructurada, el conjunto de los datos
												que actualmente se est&aacute;n tratando
											</p>
											<p>
												(vi)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												Derecho a retirar su consentimiento al tratamiento de
												sus datos personales
											</p>
											<p>
												(vii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												Derecho a no ser objeto de decisiones individuales
												automatizadas.
											</p>
											<p>
												Los participantes pueden ejercitar, si lo desean, los
												derechos de acceso, rectificaci&oacute;n y
												supresi&oacute;n de datos, as&iacute; como solicitar que
												se limite el tratamiento de sus datos personales,
												oponerse al mismo, solicitar la portabilidad de sus
												datos, as&iacute; como no ser objeto de decisiones
												individuales automatizadas, remitiendo una
												comunicaci&oacute;n por escrito a Schweppes, S.A., Paseo
												de la Castellana, 202, Madrid, 28046 o bien mediante el
												env&iacute;o de un correo electr&oacute;nico a la
												direcci&oacute;n{' '}
												<a href="mailto:GDPR.Info@suntory.com">
													GDPR.Info@suntory.com
												</a>{' '}
												adjuntando en ambos casos a esta solicitud, copia de su
												DNI O Tarjeta&nbsp; de Residencia y especificando el
												derecho que desea ejercitar.
											</p>
											<p>
												<strong>
													&iquest;Ante que autoridad puede ejercitar sus
													reclamaciones?{' '}
												</strong>
											</p>
											<p>
												Los participantes pueden presentar una
												reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de
												Protecci&oacute;n de Datos (
												<a href="http://www.aepd.es">www.aepd.es</a>)en
												relaci&oacute;n con la respuesta que haya recibido de
												SCHWEPPES al atender sus derechos. En cualquier caso,
												los participantes podr&aacute;n dirigirse inicialmente a
												la Oficina de Protecci&oacute;n de Datos de Schweppes, a
												trav&eacute;s de la direcci&oacute;n
												GDPR.Info@suntory.com o bien en la direcci&oacute;n
												Schweppes, S.A., Paseo de la Castellana, 202, Madrid,
												28046, para poder solucionar cualquier
												reclamaci&oacute;n con el fin de que podamos ayudarle a
												este respecto.
											</p>
											<p>
												Los datos de car&aacute;cter personal que se entreguen
												para la celebraci&oacute;n, ejecuci&oacute;n y
												mantenimiento de los servicios regulados en estas bases
												legales ser&aacute;n tratados con estricto y fiel
												cumplimiento de la normativa en materia de
												protecci&oacute;n de datos personales vigente en cada
												momento y ser&aacute;n &uacute;nica y exclusivamente
												aqu&eacute;llos estrictamente necesarios para gestionar
												la participaci&oacute;n en la presente Promoci&oacute;n
												y la entrega de los premios derivados de la misma.
											</p>
										</td>
									</tr>
								</tbody>
							</table>
							<p>
								<strong>
									<u>&nbsp;</u>
								</strong>
							</p>

							<h5>
								<strong>
									<u>12. MODIFICACI&Oacute;N DE LAS PRESENTES BASES LEGALES</u>
								</strong>
							</h5>

							<p>
								SCHWEPPES se reserva el derecho a modificar total o parcialmente
								las presentes Bases Legales, incluyendo, modificando o
								eliminando cl&aacute;usulas, sin asumir responsabilidad alguna
								por tales modificaciones, siempre que medie causa justificada o
								fuerza mayor, comprometi&eacute;ndose a comunicar con suficiente
								antelaci&oacute;n las nuevas bases y condiciones de
								participaci&oacute;n.
							</p>

							<h5>
								<strong>
									<u>13. FRAUDE </u>
								</strong>
							</h5>

							<p>
								En el caso de que SCHWEPPES o cualquier entidad que est&eacute;
								ligada profesionalmente a la presente Promoci&oacute;n detecten
								cualquier anomal&iacute;a o sospechen que un participante
								est&aacute; impidiendo el normal desarrollo en su
								participaci&oacute;n en la presente Promoci&oacute;n, alterando
								ilegalmente su registro o su participaci&oacute;n mediante
								cualquier procedimiento, t&eacute;cnico o inform&aacute;tico
								para as&iacute; falsear su participaci&oacute;n, podr&aacute; de
								forma unilateral eliminar la inscripci&oacute;n de ese
								participante. Por tanto, SCHWEPPES se reserva el derecho de
								eliminar del registro a cualquier participante que evidencie o
								del que se sospeche una actuaci&oacute;n irregular en el sentido
								descrito, sin notificaci&oacute;n alguna al mismo.
							</p>

							<h5>
								<strong>
									<u>14. FISCALIDAD</u>
								</strong>
							</h5>

							<p>
								De conformidad con la normativa del Impuesto de la Renta de las
								Personas F&iacute;sicas (&ldquo;IRPF&rdquo;), los regalos
								concedidos por la participaci&oacute;n en juegos, concursos,
								rifas, o combinaciones aleatorias vinculadas a la venta o
								promoci&oacute;n de bienes o servicios est&aacute;n sujetos a
								retenci&oacute;n o ingreso a cuenta siempre que el valor de los
								mismos sea superior a TRESCIENTOS EUROS (300 &euro;). En este
								sentido, corresponder&aacute; la realizaci&oacute;n del
								correspondiente ingreso a cuenta del IRPF del ganador del sorteo
								a la entidad mercantil SCHWEPPES. En caso de que el ganador no
								disfrute del Premio, no proceder&aacute; la realizaci&oacute;n
								de ingreso a cuenta alguno.
							</p>
							<p>
								Lo anterior, sin perjuicio de la obligaci&oacute;n del ganador
								del Premio de realizar la declaraci&oacute;n correspondiente en
								su IRPF. A tal fin, SCHWEPPES expedir&aacute; la oportuna
								certificaci&oacute;n que facilite al participante el
								cumplimiento de sus obligaciones fiscales. En virtud de lo
								dispuesto en la normativa vigente, a efectos de la
								realizaci&oacute;n del ingreso a cuenta, la valoraci&oacute;n
								del premio ser&aacute; el resultado de incrementar en un 20 por
								ciento el coste de adquisici&oacute;n del viaje para SCHWEPPES
								(incluyendo el IVA soportado).
							</p>
							<p>
								Adicionalmente a lo anterior, corresponder&aacute; a SCHWEPPES
								el pago del Impuesto sobre Actividades del Juego que resulta
								aplicable a la Promoci&oacute;n. La base imponible estar&aacute;
								constituida por el importe total del valor de mercado de los
								premios sorteados, resultando de aplicaci&oacute;n un tipo
								impositivo del 10%. Este impuesto ser&aacute; objeto de
								liquidaci&oacute;n administrativa por parte de la
								Administraci&oacute;n Tributaria.
							</p>

							<h5>
								<strong>
									<u>15. ACEPTACI&Oacute;N DE LAS BASES</u>
								</strong>
							</h5>

							<p>
								La participaci&oacute;n en esta Promoci&oacute;n supone la plena
								aceptaci&oacute;n de las presentes bases legales. La simple
								participaci&oacute;n implica la aceptaci&oacute;n de estas Bases
								en su totalidad, por lo que la manifestaci&oacute;n, en el
								sentido de no aceptaci&oacute;n de las mismas, implicar&aacute;
								la exclusi&oacute;n del participante y, como consecuencia de la
								misma SCHWEPPES quedar&aacute; liberada del cumplimiento de la
								obligaci&oacute;n contra&iacute;da con dicho participante.
								Asimismo, los participantes se comprometen a admitir la
								decisi&oacute;n interpretativa que SCHWEPPES pueda darles sobre
								las presentes bases o sobre cualquier cuesti&oacute;n derivada
								de la Promoci&oacute;n no contemplada en &eacute;stas.
							</p>

							<h5>
								<strong>
									<u>16. ESTABLECIMIENTOS ADHERIDOS A LA PROMOCI&Oacute;N</u>
								</strong>
							</h5>

							<p>
								Se consideran Establecimientos Adheridos a la presente
								promoci&oacute;n los puntos de venta detallados debajo. En caso
								de que se indique la cadena/grupo, ser&aacute;n todos los
								establecimientos propios o franquiciados de dicho grupo:
							</p>
							<p>
								CARREFOUR de la Comunidad de Madrid que se adhieran a la
								promoci&oacute;n.
							</p>
							<p>
								Todos los establecimientos de HIBER y PLAZA de la Comunidad de
								Madrid que se adhieran a la promoci&oacute;n.
							</p>
							<p>
								Todos los establecimientos de HIPER USERA de &aacute;mbito
								nacional que se adhieran a la promoci&oacute;n.
							</p>
							<p>
								Todos los establecimientos de ALCAMPO de &aacute;mbito nacional
								que se adhieran a la promoci&oacute;n.
							</p>
							<h3>
								<strong>
									<u>LEY Y JURISDICCI&Oacute;N APLICABLE</u>
								</strong>
							</h3>
							<p>
								La Promoci&oacute;n se rige por la legislaci&oacute;n vigente en
								Espa&ntilde;a.
							</p>
							<p>
								Para toda controversia que pudiera surgir en la
								interpretaci&oacute;n y aplicaci&oacute;n de las presentes bases
								ser&aacute;n competentes los tribunales que determine la ley.
							</p>

							<h5>
								<strong>
									<u>17. DEP&Oacute;SITO DE LAS BASES </u>
								</strong>
							</h5>

							<p>
								Las Bases de la presente promoci&oacute;n est&aacute;n
								depositadas y protocolizadas ante la Notar&iacute;a A.S. 201 C.B
								con domicilio en la Calle Arturo Soria, 201-1&ordm; A y
								publicadas en el Archivo Electr&oacute;nico de Bases de
								Concursos (ABACO) servicio de inter&eacute;s general ofrecido
								por el Consejo General del Notariado y publicado en
								http://www.notariado.org/liferay/web/notariado/e-notario/abaco.
							</p>
							<p>
								Tambi&eacute;n, las presentes bases estar&aacute;n a
								disposici&oacute;n de cualquier persona que las desee consultar
								en la p&aacute;gina Web de Tonicaperfectapartidoperfecto.com
								pudiendo ser consultadas en cualquier momento.
							</p>
							<p>En Madrid&nbsp;a 18 de Marzo de 2024</p>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BBLLPage;
