import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import * as Responsive from '../Responsive';

//images
import Pelota from '../../css/images/pelota.png';
import Lata from '../../css/images/Lata-Tonica-Origina.png';
import LataMobile from '../../css/images/lata-pelota-movil.png';

const Floatings = ({ noBall }) => {
	return (
		<div className="floatings">
			<Responsive.Desktop>
				<Parallax translateY={[-20, 20]} className="lata">
					<img src={Lata} alt="Lata" className="img-fluid" />
				</Parallax>

				{!noBall && (
					<Parallax
						translateY={[0, 20]}
						speed={10}
						translateX={[20, 0]}
						className="pelota"
					>
						<img src={Pelota} alt="Pelota" className="img-fluid" />
					</Parallax>
				)}
			</Responsive.Desktop>
			<Responsive.Mobile>
				<div className="bodegon-mobile">
					<img src={LataMobile} alt="Lata" className="img-fluid" />
				</div>
			</Responsive.Mobile>
		</div>
	);
};

export default Floatings;
