import React, { useRef } from 'react';
import { useField } from 'formik';

//bs
import { FormGroup, FormFeedback, Col, Badge } from 'reactstrap';

//image
import IconCamera from '../../css/images/icono-camara.svg';
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

const InputFile = ({ label, ...props }) => {
	const inputFileRef = useRef();
	const [field, meta, helpers] = useField(props.name);

	const handleFileChange = (event) => {
		const file = event.currentTarget.files[0];
		if (file === field.value) {
			event.target.value = null; // Reset the input value
		} else {
			helpers.setValue(file);
		}
	};

	const handleFileRemove = () => {
		inputFileRef.current.value = null;
		helpers.setValue(null);
	};

	return (
		<FormGroup className="mt-4 mb-4" row>
			<Col sm="10" className="d-flex align-items-center">
				<div
					className="filebutton"
					onClick={() => inputFileRef.current.click()}
				>
					Sube una foto de tu ticket de compra
				</div>
			</Col>
			<Col sm="2">
				<div className="camera">
					<img
						src={IconCamera}
						alt="Cámara"
						className="img-fluid"
						onClick={() =>
							field.value ? handleFileRemove() : inputFileRef.current.click()
						}
					/>
					{meta.touched && meta.error ? (
						<Badge color="danger">
							<IoClose />
						</Badge>
					) : field.value ? (
						<Badge color="success">
							<FaCheck />
						</Badge>
					) : null}
				</div>
			</Col>
			<input
				type="file"
				id={props.name}
				accept="image/jpeg, image/png, image/gif, image/jpg"
				onChange={handleFileChange}
				style={{ display: 'none' }}
				ref={inputFileRef}
			/>

			<Col sm="12">
				{meta.touched && meta.error && (
					<FormFeedback>{meta.error}</FormFeedback>
				)}
			</Col>
		</FormGroup>
	);
};

export default InputFile;
