import React, { useState, useRef } from 'react';
import AWS from 'aws-sdk';

//bs
import { Container, Row, Col, Button } from 'reactstrap';

import {
	S3_ACCESS_KEY,
	S3_BUCKET,
	S3_FOLDER,
	S3_REGION,
	S3_SECRET_KEY,
	renameFile,
	SUPERMERCADOS,
	PRODUCTOS,
} from '../../utils/helpers';

//form
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputFile from '../../components/common/InputFile';
import InputForm from '../../components/common/InputForm';

//comps
import LoadingLayer from '../../components/loaders/LoadingLayer';
import { swalError } from '../../utils/helpers';

//service
import * as mainApi from '../../api/mainApi';
import InputCheck from '../../components/common/InputCheck';

const FormPromo = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	const [apiResponse, setApiResponse] = useState(null);

	const formikRef = useRef();

	const emptyForm = {
		nombre: '',
		apellidos: '',
		email: '',
		fcompra: '',
		telef: '',
		file: '',
		establecimiento: '0',
		producto: '0',
		bbll: false,
		edad: false,
	};

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required('El nombre es obligatorio'),
		apellidos: Yup.string().required('Los apellidos son obligatorios'),
		email: Yup.string()
			.email('Ingresa un formato de correo válido')
			.required('El email es obligatorio'),
		file: Yup.mixed()
			.required('El ticket de compra es obligatorio')
			.test('fileSize', 'La imagen del ticket es superior a 5MB', (value) => {
				return value && value.size <= 5000000; // 5MB
			})
			.test(
				'fileFormat',
				'El ticket debe ser en formato JPG, PNG o GIF',
				(value) => {
					return (
						value &&
						['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
							value.type
						)
					);
				}
			),

		fcompra: Yup.date()
			.required('La fecha y hora de la compra es obligatoria')
			.min(new Date(2024, 2, 10), 'La promoción empieza el 22 de marzo de 2024')
			.max(
				new Date(),
				'La fecha y hora de la compra debe ser menor o igual que la actual'
			),
		telef: Yup.string()
			.required('El teléfono es obligatorio')
			.matches(/^\d{9}$/, 'El teléfono debe tener 9 dígitos'),
		bbll: Yup.boolean().oneOf(
			[true],
			'Debes leer y aceptar las bases legales de la promoción'
		),
		edad: Yup.boolean().oneOf([true], 'Debes ser mayor de edad'),
		establecimiento: Yup.string()
			.required('El establecimiento es obligatorio')
			.notOneOf(['0'], 'Debes seleccionar un establecimiento válido'),
		producto: Yup.string()
			.required('El producto es obligatorio')
			.notOneOf(['0'], 'Debes seleccionar un producto válido'),
	});

	const gotoUpper = () => {
		const element = document.querySelector('.participa');
		if (element) {
			if ('scrollBehavior' in document.documentElement.style) {
				element.scrollIntoView({ behavior: 'smooth' });
			} else {
				element.scrollIntoView();
			}
		}
	};

	const handleSubmitForm = async (formValues) => {
		setIsLoading(true);

		const tmpFile = formValues.file;
		const renamedFile = renameFile(tmpFile);

		AWS.config.update({
			accessKeyId: S3_ACCESS_KEY,
			secretAccessKey: S3_SECRET_KEY,
		});

		const s3 = new AWS.S3({
			params: { Bucket: S3_BUCKET },
			region: S3_REGION,
		});

		const params = {
			Bucket: S3_BUCKET,
			Key: S3_FOLDER + renamedFile.name,
			Body: renamedFile,
		};

		var upload = s3
			.putObject(params)
			.on('httpUploadProgress', (evt) => {
				console.log(
					'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%'
				);
			})
			.promise();

		await upload
			.then(async () => {
				const data = await mainApi.sendParticipation({
					...formValues,
					imgurl: renamedFile.name,
					file: null,
				});
				if (data && data.succeded) {
					setApiResponse(data.message);
					setIsVisible(!isVisible);
					gotoUpper();
					formikRef.current.resetForm(emptyForm);
				} else {
					swalError(data.message);
				}
			})
			.catch((err) => {
				//console.error('Error uploading file:', err);
				swalError(
					'Ha ocurrido un error subiendo el ticket de compra. Inténtalo de nuevo en unos minutos.'
				);
			});

		setIsLoading(false);
	};

	return (
		<div className="participa-cont">
			<LoadingLayer visible={isLoading} />
			<Container className="text-center">
				<div className={`slide-form ${!isVisible ? '' : 'sended'}`}>
					<Row>
						<Col md="12" className="mt-4">
							<Formik
								innerRef={formikRef}
								enableReinitialize
								initialValues={emptyForm}
								validateOnBlur={false}
								validateOnChange={false}
								validationSchema={validationSchema}
								onSubmit={handleSubmitForm}
							>
								{({ isSubmitting }) => {
									return (
										<Form autoComplete="off">
											<div className="main-form">
												<InputForm isRow label="Nombre" name="nombre" />
												<InputForm isRow label="Apellidos" name="apellidos" />
												<InputForm
													isRow
													label="Email"
													name="email"
													type="email"
												/>
												<InputForm isRow label="Teléfono" name="telef" />

												<InputFile name="file" />

												<InputForm
													label="Fecha y hora de la compra"
													name="fcompra"
													type="datetime-local"
													max={new Date().toISOString().split('.')[0]}
													isRow
													rowBig
												/>

												<InputForm
													label="Establecimiento"
													name="establecimiento"
													options={SUPERMERCADOS}
													type="select"
													hideLabel
												/>
												<InputForm
													label="Producto"
													name="producto"
													options={PRODUCTOS}
													type="select"
													hideLabel
												/>

												<InputCheck
													name="edad"
													label="Confirmo que soy mayor de 18 años"
												/>

												<InputCheck
													name="bbll"
													label="He leído y acepto las bases legales y la política de privacidad de esta promoción"
												/>
												<div className="text-center">
													<Button
														type="submit"
														color="send"
														className="mx-auto"
														disabled={isSubmitting}
													>
														PARTICIPAR
													</Button>
												</div>
											</div>
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</div>

				<div className={`result ${!isVisible ? '' : 'visible'}`}>
					<Row className="thx-form">
						<Col md="12">
							{apiResponse && (
								<div dangerouslySetInnerHTML={{ __html: apiResponse }} />
							)}
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	);
};

export default FormPromo;
